var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between px-2"},[_c('div',{staticClass:"flex items-center space-x-2"},[(_vm.connectError)?[_c('span',{staticClass:"text-red-400 text-xs"},[_vm._v(" "+_vm._s(_vm.connectError)+" ")])]:[(!_vm.deviceType && !_vm.isBoardConnected)?_c('span',{staticClass:"text-gray-400 text-xs uppercase tracking-wide"},[_vm._v(" "+_vm._s(_vm.isConnecting ? 'Connecting...' : 'Connect Board')+" ")]):_vm._e(),(_vm.deviceType && _vm.isBoardConnected)?_c('span',{staticClass:"text-gray-400 text-xs uppercase tracking-wide"},[_vm._v(" "+_vm._s(_vm.deviceType)+" ")]):_vm._e()],(_vm.newBuildAvailable)?_c('button',{staticClass:"text-gray-400",on:{"click":_vm.startFirmwareSync}},[_c('downloadSvg',{staticClass:"h-5 fill-current"})],1):_vm._e()],2),_c('div',{staticClass:"flex items-center"},[(_vm.connectError)?_c('div',{staticClass:"pl-8"},[_c('exclamationCircleSvg',{staticClass:"h-5 text-red-400"})],1):_vm._e(),(!_vm.connectError)?_c('button',{staticClass:"relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500",class:{
                'bg-green-600 pointer-events-none': _vm.isBoardConnected === true,
                'bg-gray-400': _vm.isBoardConnected === false,
                'opacity-50 cursor-wait': _vm.isConnecting,
            },attrs:{"type":"button","role":"switch","aria-checked":"false"},on:{"click":() => !_vm.isConnecting && _vm.startStatusWatcher()}},[_c('span',{staticClass:"sr-only"},[_vm._v("Connect board")]),_c('span',{staticClass:"translate-x-0 pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",class:{
                    'translate-x-5': _vm.isBoardConnected === true,
                    'translate-x-0': _vm.isBoardConnected === false,
                }},[_c('span',{staticClass:"absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",class:{
                        'opacity-0 ease-out duration-100': _vm.isBoardConnected === true,
                        'opacity-100 ease-in duration-200': _vm.isBoardConnected === false,
                    },attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"h-3 w-3 text-gray-400",attrs:{"fill":"none","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('span',{staticClass:"absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",class:{
                        'opacity-100 ease-in duration-200': _vm.isBoardConnected === true,
                        'opacity-0 ease-out duration-100': _vm.isBoardConnected === false,
                    },attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"h-3 w-3 text-green-600",attrs:{"fill":"currentColor","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"}})])])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="flex justify-between px-2">
        <div class="flex items-center space-x-2">
            <template v-if="connectError">
                <span
                    class="text-red-400 text-xs">
                    {{ connectError }}
                </span>
            </template>
            <template v-else>
                <span
                    v-if="!deviceType && !isBoardConnected"
                    class="text-gray-400 text-xs uppercase tracking-wide">
                    {{ isConnecting ? 'Connecting...' : 'Connect Board' }}
                </span>
                <span
                    v-if="deviceType && isBoardConnected"
                    class="text-gray-400 text-xs uppercase tracking-wide">
                    {{ deviceType }}
                </span>
            </template>
            <button
                v-if="newBuildAvailable"
                @click="startFirmwareSync"
                class="text-gray-400">
                <downloadSvg class="h-5 fill-current" />
            </button>
        </div>
        <div class="flex items-center">
            <div v-if="connectError" class="pl-8">
                <exclamationCircleSvg class="h-5 text-red-400" />
            </div>
            <button
                v-if="!connectError"
                type="button"
                class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                :class="{
                    'bg-green-600 pointer-events-none': isBoardConnected === true,
                    'bg-gray-400': isBoardConnected === false,
                    'opacity-50 cursor-wait': isConnecting,
                }"
                @click="() => !isConnecting && startStatusWatcher()"
                role="switch"
                aria-checked="false">
                <span class="sr-only">Connect board</span>
                <span
                    class="translate-x-0 pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    :class="{
                        'translate-x-5': isBoardConnected === true,
                        'translate-x-0': isBoardConnected === false,
                    }">
                    <span
                        class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                        :class="{
                            'opacity-0 ease-out duration-100': isBoardConnected === true,
                            'opacity-100 ease-in duration-200': isBoardConnected === false,
                        }"
                        aria-hidden="true"
                    >
                        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                            <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <span
                        class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                        :class="{
                            'opacity-100 ease-in duration-200': isBoardConnected === true,
                            'opacity-0 ease-out duration-100': isBoardConnected === false,
                        }"
                        aria-hidden="true"
                    >
                        <svg class="h-3 w-3 text-green-600" fill="currentColor" viewBox="0 0 12 12">
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                    </span>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import { getSessionId } from '@/utils/auth';

export default {
    name: 'IotDeviceCtrl',

    data() {
        return {
            statusInterval: null,
            isConnecting: false,
            connectError: '',
        };
    },

    computed: {
        ...mapState('iotDevice', [
            'isBoardConnected',
            'isSyncingBuild',
            'newBuildAvailable',
            'newBuildSrc',
            'deviceType',
        ]),
    },

    beforeDestroy() {
        this.stopStatusWatcher();
        this.setDeviceType('');
    },

    methods: {
        ...mapActions('iotDevice', [
            'getDeviceStatus',
            'syncBinary',
        ]),

        ...mapMutations('iotDevice', [
            'setShowDeviceSyncCtrl',
            'setIsBoardConnected',
            'setDeviceType',
        ]),

        startStatusWatcher() {
            if (!this.statusInterval) {
                this.isConnecting = true;
                this.statusWatcher();

                this.statusInterval = setInterval(() => {
                    this.statusWatcher();
                }, 60000);
            }
        },

        stopStatusWatcher() {
            if (this.statusInterval) {
                clearInterval(this.statusInterval);
                this.setIsBoardConnected(false);
                this.isConnecting = false;
            }
        },

        async statusWatcher() {
            try {
                const sessionId = getSessionId();
                await Promise.race([
                    this.getDeviceStatus({ sessionId }),
                    new Promise((_, reject) => setTimeout(
                        () => reject(new Error('timeout')), 30000,
                    )),
                ]);
                if (this.isConnecting === true) {
                    this.isConnecting = false;
                }
            } catch (ex) {
                if (ex.message === 'timeout') {
                    this.stopStatusWatcher();
                    this.connectError = 'Failed. Make sure your IoT board and CLI are connected.';
                    setTimeout(() => {
                        this.connectError = '';
                    }, 10000);
                } else {
                    console.error(ex);
                }
            }
        },

        startFirmwareSync() {
            this.setShowDeviceSyncCtrl(true);
        },
    },

    components: {
        downloadSvg: () => import('@/assets/img/download.svg'),
        exclamationCircleSvg: () => import('@/assets/img/exclamationCircle.svg'),
    },
};
</script>
